/* src/components/blog.css */

.blog-container {
    display: flex;
    flex-direction: column; /* Align children vertically */
    align-items: center; /* Center-align children horizontally */
    padding: 20px;
    margin-top: 80px; /* Adds margin to separate from the heading */
    background-color: rgb(246, 238, 251);
}

h1 {
    margin: 20px; /* Ensures there's space around the heading */
    text-align: center; /* Center-aligns the heading */
    width: 100%; /* Ensures the heading takes up full width */
}

ul {
    display: flex;
    flex-wrap: wrap; /* Allows the posts to wrap to the next line if there's not enough space */
    justify-content: center; /* Center-aligns posts horizontally */
    padding: 0;
    list-style: none; /* Removes default list styling */
    margin: 0; /* Removes default margin */
}

.post-preview {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    margin: 20px 40px;
    margin-bottom: 0;
    width: 300px; /* Adjust width as needed */
    max-width: 100%; /* Ensures the post doesn’t exceed container width */
    cursor: pointer;
    transition: box-shadow 0.3s ease;
    text-align: center; /* Center-aligns text within the post preview */
    background-color: #fff; /* Adds background color for contrast */
}

.post-preview:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.post-preview img {
    width: 100%;
    height: auto;
}

.post-preview .post-header {
    padding: 10px;
    background-color: #f9f9f9;
}

.post-preview .post-header h3 {
    margin: 0;
    font-size: 1.5rem;
}

.post-preview .post-header p {
    margin: 5px 0;
    color: #555;
    font-style: italic; /* Optional: Make the author text italic */
}

.post-preview a {
    text-decoration: none; /* Remove underline from links */
    color: inherit; /* Ensure the link inherits color from parent */
}

.post-preview a:hover {
    color: rgb(56, 2, 2); /* Optional: Change color on hover */
}

.post-content {
    display: none;
    padding: 10px;
}

.post-content.active {
    display: block;
}
